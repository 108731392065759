<i18n lang="json">
{
  "en": {
    "title": "Find your perfect spot",
    "headTitle": "@:title {'|'} RVezy Campgrounds",
    "exploreResults": "Explore {count} nearby campgrounds",
    "campgroundsNearYou": "Campgrounds near you",
    "search": "Search",
    "location": "Location",
    "locationPlaceholder": "Where will you be camping?"
  },
  "fr": {
    "title": "Trouvez l'endroit idéal",
    "headTitle": "@:title {'|'} Campings RVezy",
    "exploreResults": "Découvrez {count} campings à proximité",
    "campgroundsNearYou": "Terrains de camping proches de chez vous",
    "search": "Recherche",
    "location": "Location",
    "locationPlaceholder": "Où allez-vous camper ?"
  }
}
</i18n>

<template>
  <div
    ref="spacerEl"
    class="z-50 min-h-[40rem] max-h-[60rem] h-[calc(100vh-128px)] -mt-[var(--app-header-height)] pointer-events-none"
  />
  <div
    ref="heroSectionEl"
    :style="heroStyles"
    class="bg-[image:var(--hero-mobile-background-url)] sm:bg-[image:var(--hero-desktop-background-url)] w-full min-h-[40rem] max-h-[60rem] h-[calc(100vh-128px)] bg-cover bg-right-bottom absolute top-0 pt-[var(--app-header-height)]"
  >
    <div class="bg-gradient-to-b from-black/40 absolute top-0 size-full">
      <div class="flex h-full items-center justify-center">
        <div class="max-w-[90%] sm:max-w-md">
          <h1 class="title-6 text-white mb-4">{{ t('title') }}</h1>
          <div class="rounded-full bg-highlight-50 size-full p-4 border border-primary">
            <PlaceSearch v-slot="{ search }">
              <FormButton class="w-fit bg-highlight text-white" @click="search">{{ t('search') }}</FormButton>
            </PlaceSearch>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="p-4">
    <template v-if="searchStatus === 'success' && searchResults">
      <h2 class="subtitle-3 mb-4 lg:-mb-8">{{ t('campgroundsNearYou') }}</h2>
      <Shelf name="HomepageCampgrounds" :step="isLargeScreen ? 2 : 1" :show-controls="isLargeScreen" class="mb-4">
        <div v-for="(campground, index) in searchResults.results" :key="campground.id" class="w-64">
          <CardCampground :list-id :position="index + 1" :campground class="w-64" load-first-image />
        </div>
      </Shelf>

      <LinkButton
        :href="searchLink"
        class="bg-white text-primary border-2 border-primary py-3 px-4 lg:w-fit block"
        @click="handleExploreNearbyCampgroundsClick"
        >{{ t('exploreResults', { count: searchResults.totalResults }) }}</LinkButton
      >
    </template>
  </div>
</template>

<script lang="ts" setup>
import { useResizeObserver } from '@vueuse/core'
import type { Campground, LanguageCode, PagedResult } from '~/types/api'
import type { Properties as CampgroundSegmentTypes } from '~/types/campground-segment-types'

type SegmentProduct = CampgroundSegmentTypes['Product List Viewed']['products'][0]

definePageMeta({
  layout: 'homepage'
})

const handleExploreNearbyCampgroundsClick = () => {
  trackCtaClicked({
    id: 'exploreNearbyCampgrounds',
    label: t('exploreResults', { count: searchResults.value?.totalResults }),
    url: searchLink.value!
  })
}

const handleHeroResize = () => {
  heroHeight.value = heroSectionEl.value?.scrollHeight ?? 0
  spacerStyles.value['margin-top'] = `${heroHeight.value}px`

  const diff = heroHeight.value - (spacerEl.value?.offsetTop ?? 0)
  if (diff !== 0) {
    setTimeout(() => {
      spacerStyles.value['margin-top'] = `${heroHeight.value + diff}px`
    })
  }
}

const trackSearchResults = () => {
  if (searchResults.value == null) return

  segmentTrack('Product List Viewed', {
    list_id: listId.value,
    products: searchResults.value.results?.map((campground, index): SegmentProduct => {
      return {
        brand: campground.brand ?? undefined,
        category: 'Campground',
        name: campground.name!,
        position: index + 1,
        product_id: campground.id!,
        url: getLocalePath({ name: 'campground-slug', params: { slug: campground.slug ?? campground.id } }),
        currency: getCurrencyCode(campground.countryCode!),
        image_url: campground.photos?.at(0)
      }
    }) as SegmentProduct[],
    pagination: {
      current_page: 1,
      items_per_page: resultsPerPage
    }
  })
}

const { t, locale } = useI18n()
const isLargeScreen = useIsLargeScreen()
const geolocation = useGeolocation()
const resultsPerPage = 20

const languageCode = computed(() => locale.value as LanguageCode)
const searchLink = computed(() => {
  return getLocalePath({
    name: 'search',
    query: { SearchAddress: [geolocation.city, geolocation.regionCode, geolocation.city].filter((x) => x).join(', ') }
  })
})

const desktopHeroImage = cdnStaticImage('/homepage-hero.webp')
const mobileHeroImage = cdnStaticImage('/homepage-hero-mobile.webp')

const heroHeight = ref(0)
const heroSectionEl = ref<HTMLElement>()
const spacerEl = ref<HTMLElement>()
const heroStyles = ref<Record<string, string>>({
  // tailwinds doesn't handle dynamic images directly, this is the workaround
  '--hero-desktop-background-url': `url(${desktopHeroImage})`,
  '--hero-mobile-background-url': `url(${mobileHeroImage})`
})

const spacerStyles = ref<Record<string, string>>({})
const listId = ref(uuid())

onMounted(() => {
  useResizeObserver(heroSectionEl.value, () => {
    handleHeroResize()
  })

  handleHeroResize()
  trackSearchResults()
})

useHead({
  title: t('headTitle'),
  meta: [
    {
      key: 'og:title',
      property: 'og:title',
      content: t('headTitle')
    }
  ]
})

const {
  error: searchError,
  data: searchResults,
  status: searchStatus,
  refresh: refreshSearch
} = await useAsyncData<PagedResult<Campground>>('campground-search', async () => {
  const { data, error } = await campgroundApiClient().GET('/Campground/Search', {
    params: {
      query: {
        LanguageCode: languageCode.value,
        DistanceKm: 200,
        Latitude: geolocation.latitude,
        Longitude: geolocation.longitude,
        PageSize: resultsPerPage,
        FullImagePath: true
      }
    }
  })

  if (error != null) throw new Error(error)

  return data as PagedResult<Campground>
})

useHead({
  link: [
    {
      rel: 'preload',
      fetchpriority: 'high',
      as: 'image',
      href: isLargeScreen.value ? desktopHeroImage : mobileHeroImage
    }
  ]
})

watch(
  () => searchError.value,
  () =>
    captureError(
      toRaw(searchError.value) ??
        new Error('Error searching data for campgrounds!')
    )
)

watch(
  () => languageCode.value,
  () => refreshSearch()
)

watch(
  () => searchResults.value,
  () => {
    trackSearchResults()
  }
)
</script>
